import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PhotoGrid from "react-photo-gallery";
import { PhotoSwipeGallery } from "./photoswipe";

const galleryQuery = graphql`
  query CloudinaryImages {
    allCloudinaryMedia {
      nodes {
        secure_url
        width
        height
      }
    }
  }
`;

export function Gallery() {
  const galleryRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentIndex, setCurrentIdex] = React.useState(0);
  return (
    <StaticQuery
      query={galleryQuery}
      render={data => {
        return (
          <React.Fragment>
            <PhotoGrid
              photos={data.allCloudinaryMedia.nodes.map(node => ({
                src: node.secure_url.replace("upload/", "upload/h_300,c_fit,"),
                width: node.width,
                height: node.height,
              }))}
              onClick={(event, { index }) => {
                setCurrentIdex(index);
                setIsOpen(true);
              }}
              targetRowHeight={240}
            />
            <PhotoSwipeGallery
              ref={galleryRef}
              currentIndex={currentIndex}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              items={data.allCloudinaryMedia.nodes.map(node => ({
                src: node.secure_url.replace(
                  "upload/",
                  "upload/w_1900,h_1400,c_fit,"
                ),
                dl: node.secure_url.replace("f_auto", "f_jpg"),
                w:
                  node.width >= node.height
                    ? 1900
                    : (node.width * 1400) / node.height,
                h:
                  node.height > node.width
                    ? 1400
                    : (node.height * 1900) / node.width,
              }))}
              options={{
                shareButtons: [
                  {
                    id: "download",
                    label: "Download image",
                    url: "{{raw_image_url}}",
                    download: true,
                  },
                ],
                getImageURLForShare: function (shareButtonData) {
                  return galleryRef.current.currItem.dl || "";
                },
              }}
            />
          </React.Fragment>
        );
      }}
    />
  );
}
