import React from "react";
import { Helmet } from "react-helmet";
import styles from "./layout.module.css";

export function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
        <link rel="stylesheet" href="/reset.css"></link>
        <link rel="stylesheet" href="/fonts.css"></link>
        <body className={styles.body} />
      </Helmet>
      {children}
    </React.Fragment>
  );
}
