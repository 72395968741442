import React from "react";
import styles from "./videos.module.css";

const videos = [
  {
    id: "478310336",
    title: "Highlights Video",
    description: "A fun look at the day, set to music",
  },
  {
    id: "451315487",
    title: "Wedding Ceremony",
    description: "Officiated by Wyatt Hull",
  },
  {
    id: "451693790",
    title: "Toast - Father of the Groom",
    description: "Robert Link",
  },
  {
    id: "451707928",
    title: "Toast - Maid of Honor",
    description: "Emily McRae",
  },
  {
    id: "451704518",
    title: "Toast - Best Man",
    description: "Brandon Rutledge",
  },
  { id: "451706830", title: "Thank You", description: "From Brian and Tonya" },
];

const heightRatio = 360 / 640;

export function Videos() {
  return (
    <div className={styles.videos}>
      {videos.map(({ id, title, description }, i) => (
        <div
          className={styles.video}
          style={{ width: i === 0 ? "100%" : "50%" }}
          key={id}
        >
          <div className={styles.card}>
            <h3 className={styles.title}>
              {title} <span className={styles.description}>{description}</span>
            </h3>
            <VideoIframe id={id} />
          </div>
        </div>
      ))}
    </div>
  );
}

function VideoIframe({ id }) {
  const [width, setWidth] = React.useState(null);
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      const { width } = ref.current.getBoundingClientRect();
      setWidth(width);
    }, 1000);
    const onResize = () => {
      const { width } = ref.current.getBoundingClientRect();
      setWidth(width);
    };
    window.addEventListener("resize", onResize);
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", onResize);
    };
  }, [ref, setWidth]);

  return width ? (
    <iframe
      ref={ref}
      title="vimeo-player"
      src={`https://player.vimeo.com/video/${id}?portrait=false&byline=false&title=false`}
      width={width}
      height={width * heightRatio}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  ) : (
    <div ref={ref}></div>
  );
}
