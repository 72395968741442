import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Layout } from "../components/layout";
import { Hero } from "../components/hero";
import { Gallery } from "../components/gallery";
import { Videos } from "../components/videos";
import styles from "./index.module.css";

function SectionBreak({ className }) {
  return (
    <section className={`${styles.break} ${className}`}>
      <span className={styles.breakLine}></span>
      <span className={styles.breakSymbol}>⚘</span>
      <span className={styles.breakLine}></span>
    </section>
  );
}

export default function Home() {
  console.log("Rendering Home");
  return (
    <Layout>
      <Hero
        image="/images/walking.jpg"
        alt="Brian and Tonya holding hands"
        width={4503}
        height={2698}
      >
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <h1 className={styles.title}>Brian & Tonya Link</h1>
            <p className={styles.subtitle}>
              August <span className={styles.numbers}>21, 2020</span>
              &nbsp;&nbsp;⚘&nbsp;&nbsp;Aptos, CA
            </p>
          </div>
        </div>
      </Hero>
      <section className={styles.nav}>
        <AnchorLink to="/#story" title="Our Wedding Story" />
        <AnchorLink to="/#photos" title="Photo Gallery" />
        <AnchorLink to="/#videos" title="Videos" />
      </section>
      <SectionBreak className={styles.firstBreak} />
      <section id="story" className={styles.section}>
        <h2>Our Wedding Story</h2>
        <div className={styles.columns}>
          <div className={styles.copy}>
            <p>
              It all started with a surprise engagement in May 2019. Brian laid
              out some romantic notes and candles lit a path for Tonya to the
              back yard. She said yes! Brian had pulled off the perfect intimate
              proposal.
            </p>
            <p>
              We quickly set out to plan our dream wedding. We knew we wanted to
              host our friends and family in a natural setting, with plenty of
              room for everyone. Roaring Camp in Felton turned out to be the
              perfect spot. With our venue booked, we started lining up all of
              the special details. For Brian, a critical detail for the wedding
              was the food. Adventurous palates is something that we share, and
              he had his heart set on unique local Food Trucks. We knew this
              would be one of the trickier details, given Brian’s penchant for
              doing a little too much research (and the general flakiness of
              playing food truck phone tag). Fortunately by January the trucks
              were booked, the menu was set, and it seemed like smooth sailing
              from there.
            </p>
            <p>
              But then COVID reared its ugly head. Tonya spent a few months in
              denial, but by early June we knew the original plan would have to
              change in a big way. With the help of our amazingly supportive
              friends and family (big shout out to Aunt Linda!), we were able to
              plan something that still allowed us to tie the knot and keep some
              of the memorable moments alive. However, 2020 wasn’t done with us
              yet...
            </p>
            <p>
              The weekend before the wedding the lightning storms hit, and by
              the time we were driving towards Aptos on Wednesday smoke had
              started to blanket the greater Bay Area. This felt like yet
              another test of our patience and resolve, so we shared a few tears
              and a few laughs and tried to remain optimistic. We followed news
              of the fire and headed into our Wedding day a little weary but
              excited none-the-less.
            </p>
            <p>
              Brian waited anxiously down by a stream in a nearby park. Then,
              Tonya tapped him on the shoulder for our "first look" and we
              shared a wonderful private moment. Later, at Tonya's aunt and
              uncle's house, we exchanged personal vows and enjoyed a few hours
              of celebration. Brian was still able to enjoy his food truck
              dining experience (special grilled cheese sandwiches from The
              Happy Anchor). Tonya even surprised him with fresh raw oysters! We
              shared our first dance, cut the cake and enjoyed the remainder of
              the evening chatting with guests. Despite everything that led up
              to the big day, it still ended up being the dream wedding we
              always hoped for.
            </p>
            <p>
              We would like to thank everyone that helped pull our special day
              together. We couldn't have done it without all your support! We'd
              also like to thank ALL of our friends and family for lending your
              care and understanding with the changing situation. It breaks our
              heart that you all weren't able to be there in person, but we look
              forward to celebrating with each of you in due time.
            </p>
            <p>
              Finally, a special thanks to all the fire and emergency personnel
              who put their lives on the line to protect Santa Cruz county. We
              can only hope that moments of love and commitment are part of what
              you fight for. We would like to join others who can in
              contributing to the long road of recovery ahead.{" "}
              <a
                href="https://www.cfscc.org/updates/fire-response-fund"
                rel="noreferrer"
                target="_blank"
              >
                Community Foundation: Santa Cruz County
              </a>
            </p>
          </div>
          <div className={styles.copyImages}>
            <img src="/images/engagement.jpg" alt="Engagement Shot" />
            <img src="/images/tall-trees.jpg" alt="Tall Trees" />
            <img src="/images/night-time.jpg" alt="Night Time" />
          </div>
        </div>
      </section>
      <SectionBreak />
      <section id="photos" className={styles.section}>
        <h2>Photo Gallery</h2>
        <p>
          It was a pleasure working with Whitney Justesen, through all the
          twists and turns of our wedding planning. She beautifully captured our
          Wedding Day (and our engagement photos).{" "}
          <a
            href="http://www.whitneyjustesen.com/"
            rel="noreferrer"
            target="_blank"
          >
            [http://www.whitneyjustesen.com/]
          </a>{" "}
        </p>
        <Gallery />
      </section>
      <SectionBreak />
      <section
        id="videos"
        className={`${styles.section} ${styles.lastSection}`}
      >
        <h2>Ceremony Videos</h2>
        <p>
          Once it was clear that many of those people important in our lives
          would miss our wedding, we knew we had to capture it in film. With
          little notice, Shannon from Seafoam Films graciously worked around our
          specific needs and was able to record the ceremony, speeches, and put
          together a fantastic highlight video.{" "}
          <a
            href="http://www.seafoamfilms.com/"
            rel="noreferrer"
            target="_blank"
          >
            [http://www.seafoamfilms.com/]
          </a>
        </p>
        <Videos />
      </section>
      <Hero
        image="/images/flowers-3.jpg"
        alt="Flowers"
        width={5760}
        height={1200}
      ></Hero>
    </Layout>
  );
}
