import React from "react";
import styles from "./hero.module.css";

export function Hero({ image, alt, width, height, style, children }) {
  return (
    <section
      className={styles.hero}
      style={{
        minHeight: `calc(100vw * ${height} / ${width})`,
        ...(style || {}),
      }}
    >
      <img className={styles.heroBackground} src={image} alt={alt} />
      <div className={styles.heroContent}>{children}</div>
    </section>
  );
}
