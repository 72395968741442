import React from "react";
import ReactDOM from "react-dom";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

const portalRoot = typeof document !== `undefined` ? document.body : null;

const PhotoSwipeDialog = React.forwardRef(function (props, ref) {
  /* eslint-disable */
  return portalRoot
    ? ReactDOM.createPortal(
        <div
          ref={ref}
          className="pswp"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          {/* Background of PhotoSwipe. It's a separate element as animating opacity is faster than rgba(). */}
          <div className="pswp__bg"></div>

          {/* Slides wrapper with overflow:hidden. */}
          <div className="pswp__scroll-wrap">
            {/* Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. */}
            <div className="pswp__container">
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
            </div>

            {/* Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. */}
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                {/*  Controls are self-explanatory. Order can be changed. */}
                <div className="pswp__counter"></div>
                <button
                  className="pswp__button pswp__button--close"
                  title="Close (Esc)"
                ></button>
                <button
                  className="pswp__button pswp__button--share"
                  title="Share"
                ></button>
                <button
                  className="pswp__button pswp__button--fs"
                  title="Toggle fullscreen"
                ></button>
                <button
                  className="pswp__button pswp__button--zoom"
                  title="Zoom in/out"
                ></button>

                {/* Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR */}
                {/* element will get class pswp__preloader--active when preloader is running */}
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div className="pswp__share-tooltip"></div>
              </div>

              <button
                className="pswp__button pswp__button--arrow--left"
                title="Previous (arrow left)"
              ></button>

              <button
                className="pswp__button pswp__button--arrow--right"
                title="Next (arrow right)"
              ></button>

              <div className="pswp__caption">
                <div className="pswp__caption__center"></div>
              </div>
            </div>
          </div>
        </div>,
        portalRoot
      )
    : null;
  /* eslint-enable */
});

export const PhotoSwipeGallery = React.forwardRef(
  (
    { currentIndex, isOpen = true, onClose, items = [], options = {} },
    galleryRef
  ) => {
    const elRef = React.useRef(null);

    // Rebuild gallery or close it depending on state of isOpen.
    React.useEffect(() => {
      if (isOpen) {
        if (!galleryRef.current) {
          galleryRef.current = new PhotoSwipe(
            elRef.current,
            PhotoSwipeUI,
            items,
            {
              ...options,
              index: currentIndex,
              history: false,
            }
          );
          galleryRef.current.listen("destroy", () => {
            galleryRef.current = null;
            onClose();
          });
          galleryRef.current.init();
        }
      } else if (galleryRef.current) {
        galleryRef.current.close();
      }
    }, [elRef, galleryRef, currentIndex, isOpen, onClose, items, options]);

    // If just the current index changes, go to that slide.
    React.useEffect(() => {
      if (galleryRef.current) {
        galleryRef.current.goTo(currentIndex);
      }
    }, [galleryRef, currentIndex]);

    return <PhotoSwipeDialog ref={elRef} />;
  }
);
